/* ===============================================================
*                    SECTION BECOME PARTNER
=============================================================== */
.become-partner-section {
  padding-top: 80px;
  padding-bottom: 0;
  padding-bottom: 80px;
  @media (max-width: @max_tablet) {
    padding-bottom: 60px;
  }

  .form-row:last-child .form-col {
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section-title {
    &:extend(.fs-46);
    letter-spacing: 0.81em / 46em;
    line-height: 54 / 46;
    margin-bottom: 0.9em;
  }

  .container {
    padding-top: 70px;
    padding-bottom: 80px;
    @media (min-width: @min_tablet) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media (max-width: @max_mobile) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}
