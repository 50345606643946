/* ===============================================================
*                            FRONT HEADER
=============================================================== */

.device-tablet {
  .front-header,
  .front-header .content-container {
    // max-height: 720px;
  }
}

.device-tablet .front-header,
.device-mobile .front-header {
  height: auto;
}

.device-tablet {
  .front-header {
    min-height: auto;
    @media (min-width: 768px) {
      min-height: 100vh;
    }
  }
}

@media (max-width: 1023px) {
  .front-header {
    min-height: auto !important;
    height: auto !important;
  }

  .front-header .content-row {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

// .device-desktop:not(.browser-ie) {
//   .front-header + * {
//     transition: .5s;
//     opacity: 0;
//   }
//   .front-header + *.visible {
//     opacity: 1;
//   }
//   .front-header {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 99;
//     background-color: @body_bg;
//     &.hide-header {
//       transition-duration: .5s;
//       transition-timing-function: ease-out;
//       // transition-timing-function: cubic-bezier(0.07, 0.51, 0.12, 1);
//       transform: translateY(-100%);
//     }
//   }
// }

.front-header-video {
  transition: 0.5s;
  opacity: 0;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-video {
  .front-header-video {
    opacity: 1;
  }
  img {
    display: none !important;
  }
}
.front-header {
  height: 100vh;
  min-height: 600px;
  position: relative;
  // min-height: 890px;
  color: #fff;

  .become-partner-btn {
    @media (min-width: @min_sm) {
      display: none;
    }
  }

  .desc-col {
    padding: 0;
  }

  .bg-img {
    // width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: @max_sm) {
    height: auto;
    min-height: 768px;
  }

  .scroll-down {
    position: absolute;
    left: 30px;
    bottom: 45px;
    transform: scale(0.8);
    z-index: 10;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      opacity: 0.6;
    }
    user-select: none;

    @media (max-width: @max_tablet) {
      display: none;
    }
  }

  .content-row {
    padding-bottom: 112px;
    padding-top: 80px;
    display: flex;
    width: 100%;

    @media (max-width: 900px) {
      display: block;
    }
    @media (max-width: @max_sm) {
      padding-bottom: 40px;
      padding-top: 0;
    }

    @media (max-height: 600px) and (orientation: landscape) {
      // padding-top: 0;
      padding-bottom: 0;
    }

    .title-col {
      margin-right: auto;
    }
    .banner-col {
      margin-left: auto;
      .banner-wrap {
        // width: auto;
        width: 265px;
      }
    }

    @media (max-width: @max_sm) {
      flex-wrap: wrap;
      & > * {
        width: 100%;
      }
    }
  }

  .desc-row {
    flex-wrap: nowrap;
    margin: 0;
    .desc-item {
      min-width: 230px !important;
      @media (max-width: @max_mobile) {
        min-width: auto !important;
      }
    }
    & > * {
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0 15px;
      flex: 1;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    .up {
      display: block;
      white-space: nowrap;
      // &:extend(.fs-14);
      font-size: 14px;
      line-height: 28 / 14;
      opacity: 0.6;
      height: 28px;
      margin-bottom: 5px;
    }

    .desc-title {
      &:extend(.fs-46);
      font-weight: bold;
      line-height: 54 / 46;
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      .count {
        padding-right: 10px;
      }
      .sign {
        transition: 0.6s;
        opacity: 0;
      }
      &.nums-loaded .sign {
        opacity: 1;
      }
    }

    .desc-subtitle {
      &:extend(.fs-16);
      font-weight: bold;
      letter-spacing: 0.6em / 16em;
      line-height: 28 / 16;
    }
  }

  .title-col {
    position: relative;
  }

  .banner-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.5s;
    opacity: 0;
    &.aos-animate {
      opacity: 1;
    }
    @media (max-width: 900px) {
      padding-top: 30px;
      // padding-top: 60px;
      // padding-bottom: 60px;
    }
    @media (min-width: @min_desktop) {
      padding-right: 7%;
    }
    @media (max-width: @max_sm) {
      justify-content: flex-start;
    }
    @media (min-width: 1659px) {
      padding-right: 7%;
    }
  }

  .banner-wrap {
    padding: 40px;
    background-color: #c51b31;
    width: 50%;
    max-width: 265px;
    transition: 0.3s;
    min-width: 250px;
    @media (max-width: 900px) {
      margin-right: auto;
    }

    @media (max-width: @max_tablet) {
      margin-bottom: 40px;
    }

    @media (max-width: @max_sm) {
      width: 100% !important;
      max-width: 300px;
    }

    @media (max-height: 600px) and (orientation: landscape) {
      margin: 0;
      // width: 100%;
    }

    &:hover {
      .date {
        color: #171717;
      }
      background-color: #fff;
      color: #c51b31;
      .svg-stroke {
        stroke: #c51b31;
      }
    }

    .date {
      color: #fff;
      transition-delay: 0;
    }

    @media (max-width: @max_tablet) {
      max-width: none;
      min-width: 300px;
    }

    @media (max-width: 650px) {
      min-width: auto;
      width: 100%;
    }

    @media (max-width: @max_sm) {
      max-width: 300px;
    }

    .date {
      &:extend(.fs-14);
      letter-spacing: 0.6em / 14em;
      line-height: 1;
      margin-bottom: 75em / 14em;
      display: block;
      transition: 0.3s;
      font-weight: bold;
      @media (max-width: @max_sm) {
        margin-bottom: 40px;
      }
      @media (max-height: 600px) and (orientation: landscape) {
        margin-bottom: 20px;
      }
    }

    .banner-title {
      // &:extend(.fs-24);
      font-size: 24px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
      line-height: 34 / 24;
      font-weight: bold;
      margin-bottom: 30em / 24em;
      @media (max-height: 600px) and (orientation: landscape) {
        margin-bottom: 15px;
      }
    }

    .banner-link {
      display: inline-block;

      .ico {
        width: 28px;
        @media (max-width: @max_desktop) {
          width: 21px;
        }
      }
      &:hover {
        .ico {
          // transform: translateX(5px);
        }
      }
    }
  }

  .page-title {
    margin: 0;
    // &:extend(.fs-80);
    font-size: 80px;
    font-weight: bold;
    line-height: 140 / 120;
    padding-bottom: 50px;
    position: relative;
    @media (max-width: @max_tablet) {
      font-size: 60px;
    }
    @media (max-width: @max_sm) {
      font-size: 40px;
    }
    @media (max-width: 420px) {
      font-size: 36px;
    }

    @media (max-height: 600px) and (orientation: landscape) {
      font-size: 60px;
    }
  }

  .title-col {
    @media (max-width: @max_sm) {
      // margin-bottom: 95px;
      margin-bottom: 40px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #121212;
    width: 100%;
    height: 100%;
  }

  .content-container {
    position: relative;
    z-index: 5;
    color: #fff;
    height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;

    padding-top: 180px;
    padding-bottom: 220px;
    // padding-bottom: 180px;

    .row {
      width: 100%;
    }

    @media (min-width: @min_lg) {
      padding-bottom: 220px;
    }

    @media (max-width: @max_sm) {
      padding-top: 150px;
      // padding-bottom: 150px;
      padding-bottom: 205px;
    }
    @media (max-height: 600px) and (orientation: landscape) {
      padding-top: 125px;
    }
  }

  .desc-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 80px;
    z-index: 6;
    overflow: hidden;
    // padding-left: 80px;
    @media (max-width: @max_desktop) {
      padding-bottom: 40px;
    }
    @media (max-width: @max_tablet) {
      padding-left: 0;
    }
    @media (max-width: @max_mobile) {
      padding-bottom: 38px;
    }
    @media (max-width: @max_sm) {
      // padding-bottom: 58.5px;
      // padding-bottom: 0;
      padding-bottom: 38px;
    }
  }

  .glide-next {
    margin-left: 28px;
  }
}
