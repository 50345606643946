/* ===============================================================
*                        SECTION OFFERS
=============================================================== */
.section-offers {
  padding-top: 130px;
  padding-bottom: 60px;
  overflow: hidden;
  // background-color: #000;
  // color: #000;

  @media (max-width: @max_tablet) {
    padding-bottom: 30px;
    padding-top: 120px;
    background-color: transparent;
    color: #fff;
  }

  @media (max-width: @max_mobile) {
    padding-top: 90px;
  }

  @media (max-width: @max_sm) {
    padding-top: 60px;
  }

  @media (max-width: 420px) {
    padding-top: 40px;
  }

  .section-title {
    text-align: center;
    &:extend(.fs-46);
    margin-bottom: 60em / 46em;
    font-weight: bold;
    @media (max-width: @max_tablet) {
      text-align: left;
    }
  }

  .offers-row {
    display: flex;
    // flex-wrap: wrap;
    @media (max-width: @max_mobile) {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .offer-col {
    transform: translateZ(0);
    // width: calc(100% / 3);
    flex: 1;
    transition: 0.3s;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    @media (max-width: @max_tablet) {
      padding: 0;
    }
    @media (max-width: @max_mobile) {
      width: 100%;
    }
  }

  .offer-item {
    position: relative;
    height: 540vw / 16.6vw;
    max-height: 600px;
    @media (max-width: @max_tablet) {
      height: 47vw;
      max-height: none;
    }
    @media (max-width: @max_mobile) {
      // height: 62vw;
      height: 78vw;
    }
    @media (max-width: @max_sm) {
      // height: 480vw / 3.6vw;
      height: 99vw;
    }
    @media (max-width: 420px) {
      height: 135vw;
    }
  }

  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    overflow: hidden;

    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      // max-height: 50vh;
      // max-width: 50vw;
      // flex-grow: 0;
      // flex-shrink: 0;
      // height: 100%;
      // width: auto;
      transition: 0.3s;
      opacity: 0;
      &.visible {
        opacity: 1;
      }
      @media (max-width: @max_mobile) {
        // width: 100%;
        // height: 100%;
        // height: auto;
        // min-height: 100%;
      }
    }
  }

  .content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;

    .logo {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      padding-bottom: 25px;
      padding-top: 20px;
      @media (max-width: @max_tablet) {
        padding-bottom: 10px;
      }
      padding-left: 10px;
      padding-right: 10px;
      a {
        max-width: 100%;
        display: inline-block;
        max-height: 100%;
      }
      img {
        width: 220px;
        max-width: 100%;
        max-height: 100%;
        transform: translateY(calc(50% + 25px));
        transition-duration: 0.3s;
      }
    }

    .desc-wrap {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 50%;
      padding-top: 25px;

      @media (max-width: @max_tablet) and (min-width: @min_tablet) {
        padding-top: 10px;
        height: 45%;
        top: 55%;
      }

      @media (max-width: 768px) {
        padding-top: 10px;
      }

      .desc {
        max-width: 320px;
        font-size: 16px;
        color: #fff;
        width: 320px;
        text-align: center;
        line-height: 24 / 16;
        letter-spacing: 0.61em / 16em;
        margin-left: auto;
        margin-right: auto;
        opacity: 0;
        transition-duration: 0.3s;
        transform: translateY(20px);
        width: 100%;
        @media (max-width: @max_tablet) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .btn-row {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 60px;
    z-index: 10;
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
    margin: 0;
    .btn {
      margin: 0;
    }
    @media (max-width: @max_tablet) and (min-width: @min_tablet) {
      padding-bottom: 20px;
    }
    @media (max-width: 768px) {
      // padding-bottom: 20px;
    }
  }

  .offer-col:hover {
    flex: 2;
    // width: 50%;
    // width: calc(100% / 3 * 2);
    .content-wrap {
      background-color: transparent;
    }
    .logo img {
      transform: none;
    }
    .desc {
      opacity: 1;
      transform: none;
    }

    .btn-row {
      opacity: 1;
      z-index: 20;
    }
  }

  .arrows-wrap {
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    display: none;
    &.active {
      display: flex;
    }

    .arrow:first-child {
      margin-right: 40px;
    }
  }
}
