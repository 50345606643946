@min_lg: 1660px;

@min_desktop: 1280px;
@max_desktop: 1659.9px;


@min_tablet: 768px;
@max_tablet: 1279.9px;

@max_mobile: 767.9px;

@min_sm: 576px;
@max_sm: 575.9px;

@max_xsm: 420px;
@min_xsm: 419.9px;


@body_bg : #121212;


@col_space : 576px;


/* ==============================================
*                     SIZES
================================================ */


.fs-120 {
  font-size: 120px;
  @media (max-width: @max_desktop) {
    font-size: 90px;
  }
  @media ( max-width: @max_tablet ) {
    font-size: 75px;
  }
  @media ( max-width: @max_mobile ) {
    font-size: 60px;
  }
}

.fs-80 {
  font-size: 80px;
  @media ( max-width: @max_desktop ) {
    font-size: 70px;
  }
  @media ( max-width: @max_mobile ) {
    font-size: 60px;
  }
}


.fs-60 {
  font-size: 60px;
  @media ( max-width: @max_tablet ) {
    font-size: 42px;
  }

  @media ( max-width: @max_sm ) {
    font-size: 30px;
  }
}

.fs-46 {
  font-size: 46px;
  @media (max-width: @max_desktop) {
    font-size: 38px;
  }
  @media ( max-width: @max_tablet ) {
    font-size: 30px;
  }
}

.fs-34 {
  font-size: 34px;
}

.fs-38 {
  font-size: 38px;
  @media (max-width: @max_desktop) {
    font-size: 30px;
  }
  @media ( max-width: @max_mobile ) {
    font-size: 20px;
  }
}

.fs-30 {
  font-size: 30px;
}

.fs-24 {
  font-size: 24px;
  @media (max-width: @max_desktop) {
    font-size: 20px;
  }
  @media ( max-width: @max_tablet ) {
    font-size: 18px;
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
  @media (max-width: @max_desktop) {
    font-size: 12px;
  }
}

.fs-12 {
  font-size: 12px;
}
