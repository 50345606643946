.section-faq {
  padding-bottom: 40px;
  .filter-container {
    background-color: #fff;
    transition: 0.3s;
    padding: 60px;
    color: #000;
    position: relative;
    overflow: hidden;

    @media (max-width: @max_mobile) {
      padding: 40px 20px;
    }
  }

  .accordion-item {
    position: relative;
    transition: 0.3s;

    &.last-item,
    &:last-child {
      border-bottom: 0;
      // margin-bottom: 0;
    }
  }

  .filter-links {
    margin-bottom: 40px;
  }
}
