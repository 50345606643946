.modal {
  .os-content,
  .os-viewport,
  .os-padding,
  .os-resize-observer-host,
  .dom-custom-scrollbar {
    height: 100%;
  }
  .os-content {
    display: flex;
  }
  .container {
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
  }

  .content-wrap {
    padding: 80px 100px;
    overflow: hidden;
    @media (max-width: @max_mobile) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (max-width: @max_sm) {
      padding-top: 50px;
      padding-bottom: 0px;
    }
  }

  .modal-title {
    &:extend(.fs-46);
    letter-spacing: 0.81em / 46em;
    line-height: 54 / 46;
    margin-bottom: 60em / 46em;
  }
}

.become-partner-modal {
  .container {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.terms-modal {
  .container {
    margin-bottom: auto;
  }
}
