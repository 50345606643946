/* ===============================================================
*                        SECTION REVIEW
=============================================================== */

.section-review {
  padding-top: 60px;
  padding-bottom: 80px;

  @media (max-width: @max_tablet) {
    padding-top: 30px;
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  @media (max-width: 576px) {
    padding-bottom: 40px;
  }
  .section-title {
    font-size: 12vw;
    width: 100%;
    margin-bottom: 70px;
    .browser-ie & {
      padding-right: 10%;
    }
    svg {
      height: 10vw;
      width: 100%;
    }

    @media (min-width: @min_lg) {
      font-size: 190px;
      svg {
        height: 145px;
      }
    }

    @media (max-width: 992px) {
      margin-bottom: 40px;
    }
    @media (max-width: @max_mobile) {
      margin-bottom: 20px;
    }
  }

  .sliders-track {
    display: flex;
    align-items: stretch;
    // overflow: hidden;
    & > * {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .slider-reviews {
    position: relative;
    padding-left: 110px;
    padding-right: 60px;
    @media (max-width: @max_tablet) {
      padding-left: 60px;
    }
    @media (max-width: 992px) {
      &:after {
        content: "";
        display: table;
        width: 100%;
        clear: both;
      }
    }
    @media (max-width: @max_mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .arrow {
    position: absolute;
    // top: calc(50% - 60px / 2);
    top: 220px;
    @media (max-width: 992px) {
      position: relative;
      float: left;
      top: auto;
    }
  }
  .arrow-prev {
    left: -20px;
    @media (max-width: 992px) {
      margin-right: 20px;
      left: 0;
    }
  }
  .arrow-next {
    right: -20px;
    @media (max-width: 992px) {
      right: 0;
    }
  }

  .slider-content {
    display: flex;
    position: relative;
    padding-top: 55px;
    padding-bottom: 55px;
    // min-height: 500vw / 16.6vw;
    @media (max-width: @max_tablet) {
      padding-top: 40px;
    }
    @media (max-width: @max_mobile) {
      padding-top: 20px;
    }
    @media (max-width: 992px) {
      display: block;
      max-height: none;
    }
  }

  .img-wrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 57%;
    height: 30vw;

    max-height: 500px;

    @media (max-width: @max_tablet) {
      width: 60%;
    }
    @media (max-width: 992px) {
      position: static;
      width: 100%;
    }
    @media (max-width: @max_mobile) {
      min-height: auto;
      height: 62vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slider-item {
    display: none;
    &:first-child {
      display: block;
    }
  }

  .content-col {
    width: 50%;
    margin-left: auto;
    position: relative;
    z-index: 10;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: @max_tablet) {
      // width: 60%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .slider-title {
    &:extend(.fs-46);
    font-weight: bold;
    margin-bottom: 62em / 46em;
    line-height: 54 / 46;
  }

  .slider-desc {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26 / 16;
    padding-left: calc(20% + 40px);
    @media (max-width: 992px) {
      padding-left: 0;
      padding-top: 40px;
    }
  }

  .desc-signature {
    padding-top: 30px;
    font-weight: bold;
    letter-spacing: 0.6em / 16em;
  }

  .arrow-red {
    position: absolute;
    // top: 5px;
    top: 6px;
    left: 0;
    width: 20%;
    height: 10px;
    background-color: #c51b31;
    display: block;
    @media (max-width: @max_sm) {
      width: 120px;
      height: 8px;
    }
  }

  .img-wrap {
    transition: 0.5s;
    &.hide-img {
      opacity: 0;
    }

    @media (max-width: 992px) {
      margin-bottom: 37px;
    }
  }

  .content-col {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    &.hide-content {
      transform: translateX(30px);
      opacity: 0;
    }
  }

  .progress-svg {
    display: block;
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);

    .progress__value {
      stroke: #c51b31;
      stroke-width: 10;
      &.transition {
        transition: 0.2s;
      }
    }
  }
}
