body {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Roboto";
  margin: 0;
  padding: 0;
  color: #fff;
  -webkit-text-size-adjust: none;
  @media (max-width: @max_desktop) {
    font-size: 16px;
  }
}

::selection {
  background: rgba(255, 0, 51, 0.38);
  color: #fff;
}

body,
.body-bg {
  background-color: @body_bg;
}

* {
  box-sizing: border-box;
}

html {
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

a {
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.g-recaptcha {
  margin-bottom: 20px;
  & > * {
    max-width: 100%;
    overflow: hidden;
  }
}

#main,
.main-width {
  width: 100%;
  position: relative;
  max-width: 2560px;
  // max-width: 2100px;
  margin-left: auto;
  margin-right: auto;
}
#main {
  // min-height: 100vh;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: inherit;
}
.site-wrap {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}
.footer {
  margin-top: auto;
}

html,
body {
  height: 100%;
}

.img-lazy-load {
  transition: 0.4s;
  opacity: 0;
  &.img-is-loaded {
    opacity: 1;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1660px;
  position: relative;
  z-index: 1;

  @media (min-width: @min_desktop) {
    width: 1180vw * 100vw / 1366vw;
  }

  @media (min-width: @min_lg) {
    width: 1179vw * 100vw / 1660vw;
  }

  @media (max-width: @max_tablet) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: @max_sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-sm {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% / 12 * 8);
  @media (max-width: @max_tablet) {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  margin-left: -20px;
  margin-right: -20px;
  .col {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.browser-ie .img-cover {
  opacity: 0 !important;
}

.svg-fill,
.svg-stroke {
  transition: 0.3s;
}

.ico {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  & > * {
    display: block;
    width: 100%;
    height: auto;
  }

  &.white-ico {
    .svg-fill {
      fill: #fff;
    }
    .svg-stroke {
      stroke: #fff;
    }
  }
}

.scroll-down {
  display: flex;
  flex-direction: column;
  .text {
    display: inline-block;
    transform: rotate(-90deg);
    transform-origin: left center;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .ico {
    padding-top: 20px;
    .browser-ie & {
      padding-top: 0;
    }
    position: relative;
    left: -10px;
    width: 18px;
  }
}

.device-mobile,
.device-tablet {
  .scroll-down {
    display: none !important;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(60px);
  }
}

svg .anim-mouse-wheel {
  animation: scroll ease 2s infinite;
}

button,
.btn,
a.btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 12px;
  &:focus {
    outline: none;
  }
}

.btn,
a.btn {
  font-weight: bold;
  transition: 0.3s;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.6em / 12em;
  line-height: 1;
  padding-top: 20em / 12em;
  padding-left: 40em / 12em;

  padding-bottom: 20em / 12em;
  padding-right: 40em / 12em;

  &.transparent-btn {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;

    .device-desktop &:hover {
      background-color: #f0f0f0;
      border-color: #f0f0f0;
      color: #c51b31;
    }
  }

  &.red-btn {
    background-color: #c51b31;
    color: #fff;
    .device-desktop &:hover {
      background-color: #f0f0f0;
      border-color: #f0f0f0;
      color: #c51b31;
    }
  }
  &.grey-btn {
    background-color: #4d4d4f;
    color: #fff;
  }

  &.submit-btn {
    font-weight: bold;
    padding: 24em / 12em 85em / 12em;
    @media (max-width: @max_mobile) {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.white-hover-btn {
    padding-left: 20px;
    padding-right: 20px;
    .device-desktop &:hover {
      background-color: #f0f0f0;
      color: #c51b31;
      .svg-stoke {
        stroke: #c51b31;
      }
      .svg-fill {
        fill: #c51b31;
      }
    }
  }

  &.red-transparent-btn {
    border: 1px solid #c51b31;
    color: #000;
    .device-desktop &:hover {
      color: #fff;
      background-color: #c51b31;
    }
  }

  &.light-btn {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff;
    .device-desktop &:hover {
      border-color: #f0f0f0;
      background-color: #f0f0f0;
      color: #c51b31;
    }
  }
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hamburger {
  padding: 0 !important;
}
.hamburger-box {
  width: 33px !important;
}
.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  width: 32px !important;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  height: 2px !important;
  border-radius: 0 !important;
}

.dropdown {
  .open-dropdown {
    display: flex;
    align-items: center;

    .ico {
      width: 7px;
      transform-origin: center;
      transition: 0.3s;
      margin-left: 13px;
    }

    .text- {
      line-height: 1;
    }
  }
}

.close-modal-btn {
  z-index: 10;
}

.home-bg {
  position: absolute;
  bottom: 27%;
  right: -29%;
  z-index: -1;
}

.overlay {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  // left: -80px;
  // width: calc(100% + 80px * 2);
  overflow: hidden;
  width: 100%;
  height: 100%;
  &.bottom-bg {
    bottom: 0;
    top: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // @media (max-width: @max_tablet) {
  //   left: 0;
  //   width: 100%;
  // }
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    flex-shrink: 0;
    flex-grow: 0;
  }
  z-index: -1;
}

// .footer {
//   @media(min-width: 2260px) {
//     width: calc(100% + 80px * 2);
//     transform: translateX(-80px);
//   }
// }

.white-bg {
  background-color: #fff;
}

.has-bg {
  position: relative;
}

.shape-wrap {
  position: relative;
  overflow: hidden;
}

.shape-bg {
  position: absolute;
  opacity: 0.6;
  svg {
    width: 100% !important;
    height: 100% !important;
  }

  &.size-xxl {
    width: 1389px;
    height: 1383px;
  }
  &.size-xl {
    width: 903px;
    height: 903px;
  }
  &.size-xl2 {
    width: 973px;
    height: 973px;
  }
  &.size-lg {
    width: 436px;
    height: 434px;
  }

  &.size-md {
    width: 207px;
    height: 206px;
  }
  &.size-md2 {
    width: 333px;
    height: 332px;
  }
  &.size-sm {
    width: 101px;
    height: 100px;
  }

  &.pos-1 {
    width: 1330px;
    height: 1330px;
    top: -750px;
    right: -600px;

    @media (max-width: 1440px) {
      width: 1267px;
      height: 1257px;
      top: -780px;
      right: -600px;
    }
    @media (max-width: 1280px) {
      width: 1200px;
      height: 1200px;
      top: -750px;
      right: -559px;
    }
    @media (max-width: 1024px) {
      width: 800px;
      height: 800px;
      top: -524px;
      right: -386px;
    }
  }
  &.pos-2 {
    top: 40px;
    left: 60%;
    width: 101px;
    height: 100px;
  }
  &.pos-3 {
    top: 7%;
    left: calc(-434px / 2);
    width: 436px;
    height: 434px;
  }
  &.pos-4 {
    top: 46%;
    left: calc(-903px / 2);
    width: 903px;
    height: 903px;
  }
  &.pos-5 {
    top: 33%;
    right: calc(-206px / 2);
    width: 207px;
    height: 206px;
  }
  &.pos-6 {
    bottom: 17%;
    left: calc(-332px / 2);
    width: 333px;
    height: 332px;
  }
  &.pos-7 {
    bottom: 14%;
    right: calc(-973px / 2);
    width: 973px;
    height: 973px;
  }
  &.pos-8 {
    top: -300px;
    left: calc(-1329px / 2);
    width: 1329px;
    height: 1328px;
  }
  &.pos-9 {
    top: 200px;
    right: calc(-100px);
    width: 333px;
    height: 332px;
  }
}

.section {
  position: relative;
}

.btn-row {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  .btn {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.svg-stroked-text {
  font-style: normal;
  font-weight: bold;
  fill: none;
  fill-opacity: 1;
  stroke: rgba(255, 255, 255, 0.7);
  stroke-width: 0.8px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;

  @media (max-width: 767.9px) {
    stroke-width: 0.3;
  }
}

.animate-image-onview {
  position: relative;
  overflow: hidden;
  &:after {
    // content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // transition: 1.5s cubic-bezier(.07,.51,.12,1) 1.3s;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
    background-color: @body_bg;
  }
  &.animate {
    &:after {
      transform: translateX(100%);
    }
  }
  &.hide {
    &:after {
      transform: none;
    }
  }
}

textarea {
  width: 100%;
  resize: none;
  max-height: 350px;
}

.delay-1 {
  transition-delay: 0.1s;
}
.delay-2 {
  transition-delay: 0.2s;
}
.delay-3 {
  transition-delay: 0.3s;
}
.delay-4 {
  transition-delay: 0.4s;
}
.delay-5 {
  transition-delay: 0.5s;
}
.delay-6 {
  transition-delay: 0.6s;
}
.delay-7 {
  transition-delay: 0.7s;
}
.delay-8 {
  transition-delay: 0.8s;
}
.delay-9 {
  transition-delay: 0.9s;
}
.delay-10 {
  transition-delay: 1s;
}

.animate-right-arrow {
  transition-duration: 0.5s;
  transform: translateZ(0);
}

/*------------------------------------*\
$Base
\*------------------------------------*/

@darkgrey: #303030;

/*------------------------------------*\
$Graph
\*------------------------------------*/

.svg-animated-graph {
  width: 100%;
  height: 100%;
  position: relative;

  svg,
  img {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: calc(100% - 80px);
    height: calc(100% - 20px);
    // margin: 36px 0px 0px 15px;
  }

  img {
    @media (max-width: @max_mobile) {
      width: calc(100% - 40px);
      height: calc(100% - 24px);
    }
  }

  .coordinates {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .ax {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      padding-bottom: 35px;
      padding-right: 70px;
      justify-content: space-between;
      @media (max-width: @max_mobile) {
        padding-bottom: 20px;
        padding-right: 38px;
      }

      & > * {
        position: relative;
        background-color: rgba(255, 255, 255, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;
        }
        span {
          display: block;
          position: absolute;
        }
      }
    }

    .ax-x {
      & > * {
        width: 1px;
        span {
          bottom: -25px;
          @media (max-width: @max_mobile) {
            bottom: -25px;
          }
        }
      }
    }

    .ax-y {
      flex-direction: column;
      & > * {
        width: 100%;
        height: 1px;
        &:first-child {
          background-color: transparent;
        }
        &:last-child {
          background-color: #fff;
        }
        span {
          right: -70px;
          @media (max-width: @max_mobile) {
            right: -43px;
          }
        }
      }
    }
  }

  .path {
    stroke-width: 10;
    stroke: url("#graphGradient");

    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;

    transition-duration: 3s;
    transition-timing-function: ease-in-out;

    .browser-ie & {
      stroke-dashoffset: 0;
      stroke-dasharray: 0;
    }
  }

  .description {
    font-family: "Roboto";
    color: lighten(@darkgrey, 50%);
    text-align: center;
    margin: 40px 0px;
  }

  &.animate .path {
    stroke-dashoffset: 0;
  }
}

.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.3s;

  &.hidden {
    opacity: 0;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.over-container {
  transform: translateX(-10.84vw);
  width: calc(100% + 10.84vw * 2);

  @media (min-width: @min_lg) {
    transform: translateX(-215px);
    width: calc(100% + 215px * 2);
  }
  @media (max-width: @max_desktop) {
    transform: translateX(-6.2vw);
    width: calc(100% + 12.7vw);
  }
  @media (max-width: @max_tablet) {
    transform: translateX(-40px);
    width: calc(100% + 80px);
  }
}

.modal-content {
  // cursor: pointer;
  transition: 0.3s;
}

.dom-slide-up {
  overflow: hidden;
  height: 0px;
  opacity: 0;
}

.form-row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: 0;
  &.additional-fields {
    display: none;
  }
  @media (max-width: @max_tablet) {
    flex-wrap: wrap;
  }
  & > * {
    padding: 0 20px;
    padding-bottom: 40px;
  }
  .form-col {
    @media (min-width: @min_tablet) {
      flex: 1;
    }
    @media (max-width: @max_tablet) {
      width: 100%;
    }
  }
  .form-col-6 {
    width: 50%;
    &:not(.no-responsive) {
      @media (max-width: @max_tablet) {
        width: 100%;
      }
    }
    &.no-responsive {
      @media (max-width: @max_sm) {
        width: 100%;
      }
    }
  }
  .form-col-12 {
    width: 100%;
  }
}

label {
  display: block;
  position: relative;
}

.input-help {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  user-select: none;

  &.error {
    padding-top: 5px;
    color: #c51b31;
  }
}

.label {
  display: block;
  font-size: 12px;
  line-height: 14 / 12;
  margin-bottom: 15em / 12em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.6em / 12em;

  .required {
    color: #c51b31;
    display: inline-block;
    padding-left: 3px;
  }
}

.red-links,
.accordion-body .content {
  a {
    display: inline-block;
    position: relative;
    color: #c51b31 !important;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #c51b31;
      transition: 0.15s;
    }
    &:hover {
      opacity: 1;
    }
    &:hover:after {
      transform: scale(0);
    }
  }
}

.input {
  border: none;
  box-shadow: none;
  background-color: #f4f4f4;
  font-size: 16px;
  padding: 1.37em 1.25em;
  width: 100%;
  border: 1px solid transparent;
  &:focus {
    outline: none;
  }

  &.has-error {
    border: 1px solid #c51b31;
  }
}

textarea.input {
  max-width: 100%;
  min-height: 140px;
}

.form-hr {
  margin-bottom: 40px;
  height: 1px;
  display: block;
  width: 100%;
  background-color: #d8d8d8;
}

a.show-additional-fields {
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 3px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
  user-select: none;
  font-size: 16px;
  &:hover {
    opacity: 0.6;
  }
}

.form-rules {
  text-align: right;
  font-size: 12px;
  .required {
    display: inline-block;
    padding-right: 3px;
    color: #c51b31;
  }
  user-select: none;
  @media (max-width: @max_sm) {
    text-align: left;
    display: flex;
    justify-content: row-reverse;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.custom-checkbox {
  display: flex;

  a {
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }

  .input-checkbox {
    display: block;
    width: 18px;
    height: 18px;

    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
    cursor: pointer;
    .check {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: 1px solid #4d4d4f;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        opacity: 0;
        transition: 0.1s;
        background-color: #4d4d4f;
      }
    }
    input {
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      margin: 0;
      z-index: -1;
      position: absolute;
      left: -100%;
      &:checked ~ .check:after {
        opacity: 1;
      }
    }
  }

  .label-checkbox {
    user-select: none;
    font-size: 12px;
    color: #000;
    line-height: 14 / 12;
    padding-top: 2px;
  }
}

.under-red {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 2px solid #c51b31;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 100%;
}

.open-dropdown {
  &.active {
    .ico {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  position: relative;
}

.mCustomScrollBox {
  max-height: 100% !important;
  height: auto !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background-color: #fff;
  transition-duration: 0.3s;
  transform: translateX(100%);
  transition-timing-function: ease-in-out;
  // transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  // opacity: 0;

  &.active {
    transform: none;
    opacity: 1;
  }
}

.close-modal-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  @media (max-width: @max_tablet) {
    top: 20px;
    right: 20px;
  }
  .close-button {
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
  }
  .close-button > div {
    position: relative;
  }
  .close-button-block {
    width: 40px;
    height: 20px;
    position: relative;
    overflow: hidden;
  }
  .close-button-block:before,
  .close-button-block:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(55% - 4px);
    display: block;
    width: 4px;
    height: 25px;
    transform-origin: bottom center;
    background: #121212;
    transition: all ease-out 280ms;
  }
  .close-button-block:last-of-type {
    transform: rotate(180deg);
  }
  .close-button .in .close-button-block:before {
    transition-delay: 280ms;
    transform: translateX(20px) translateY(-20px) rotate(45deg);
  }
  .close-button .in .close-button-block:after {
    transition-delay: 280ms;
    transform: translateX(-22px) translateY(-22px) rotate(-45deg);
  }
  .close-button .out {
    position: absolute;
    top: 0;
    left: 0;
  }
  .close-button .out .close-button-block:before {
    transform: translateX(-5px) translateY(5px) rotate(45deg);
  }
  .close-button .out .close-button-block:after {
    transform: translateX(5px) translateY(5px) rotate(-45deg);
  }
  .close-button:hover .in .close-button-block:before {
    transform: translateX(-5px) translateY(5px) rotate(45deg);
  }
  .close-button:hover .in .close-button-block:after {
    transform: translateX(5px) translateY(5px) rotate(-45deg);
  }
  .close-button:hover .out .close-button-block:before {
    transform: translateX(-20px) translateY(20px) rotate(45deg);
  }
  .close-button:hover .out .close-button-block:after {
    transform: translateX(20px) translateY(20px) rotate(-45deg);
  }
}

.form {
  // .valid-msg {
  // margin-bottom: 3px;
  // &:last-child {
  // margin-bottom: 0;
  // }
  // }
  .msg-wrapper {
    // margin-bottom: 40px;
    padding: 18px 0;

    font-size: 12px;
    line-height: 1.6;
  }

  .validate-form-msg {
    position: relative;
    background-color: rgba(197, 27, 50, 0.1);
    padding: 0 50px;
    display: none;
    &:not(.success) .success-ico {
      display: none;
    }
    .success-ico .svg-fill {
      fill: #3a7159;
    }
    &.visible {
      display: block;
      margin-bottom: 40px;
    }

    &.success {
      background-color: rgba(58, 113, 89, 0.1);
      .alert-ico {
        display: none;
      }
    }
  }

  .validate-form-ico {
    position: absolute;
    top: calc(50% - 18px / 2);
    left: 20px;
    width: 20px;
    height: 18px;

    & > * {
      width: 100%;
    }
  }
}

.news-col:last-child {
  .news-card {
    margin-bottom: 0;
  }
}

.news-card {
  background-color: rgb(29, 29, 29);
  height: 30vw;
  max-height: 440px;
  position: relative;

  @media (max-width: @max_tablet) {
    height: 35vw;
  }

  @media (max-width: @max_mobile) {
    height: 90vw;
  }

  .thumb {
    width: 100%;
    height: 100%;
    position: absolute;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        to top,
        #121212,
        rgba(18, 18, 18, 0.8) 40%,
        transparent
      );
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    padding: 30px;
    padding-bottom: 40px;
    transition: 0.3s;

    font-weight: bold;

    @media (max-width: @max_mobile) {
      min-height: auto;
    }
  }

  &:hover {
    .content {
      background-color: rgba(197, 27, 50, 0.75);
    }
  }

  .date {
    font-size: 12px;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 0.6em / 12em;
  }

  .desc {
    font-size: 20px;
    line-height: 28 / 20;
    letter-spacing: 0.2em / 20em;
  }
}

.col-3 {
  display: flex;
  align-items: stretch;

  margin: 0 -20px;
  margin-bottom: -40px;

  flex-wrap: wrap;

  & > * {
    padding: 0 20px;
    padding-bottom: 40px;
    width: calc(100% / 3);
    flex-shrink: 0;
    flex-grow: 0;
    @media (max-width: @max_tablet) {
      width: 50%;
    }
    @media (max-width: @max_mobile) {
      width: 100%;
    }
  }
}

.has-preloader {
  position: relative;
}

.ajax-preloader {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-content: center;
  justify-content: center;

  opacity: 0;
  transition: 0.3s;

  & > * {
    width: 92px;
  }

  &.visible {
    opacity: 1;
  }
}

.news-list-section {
  padding-bottom: 40px;
}

.list-cards + .pagination {
  padding-top: 80px;
}

/*==============================================================
*												pagination
=============================================================== */

.pagination {
  display: flex;
  align-items: stretch;
  justify-content: center;

  user-select: none;
  padding-top: 40px;

  @media (max-width: @max_mobile) {
    flex-wrap: wrap;
    // justify-content: space-between;
  }

  .pag-item,
  .pag-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    transition: 0.3s;
    color: #fff;
    cursor: pointer;
    height: 50px;
  }

  .pag-numbers {
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    @media (max-width: @max_mobile) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      order: 1;
    }
  }

  .pag-item {
    width: 50px;

    &.current,
    &.current:hover {
      cursor: auto;
      background-color: rgba(102, 102, 102, 0.2);
      color: #fff;
    }

    &:hover {
      // background-color: rgba(102, 102, 102, 0.2);
      background-color: #f0f0f0;
      color: #c51b31;
    }
  }

  .pag-btn {
    border: 1px solid #fff;
    width: auto;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;

    &:hover {
      background-color: #f0f0f0;
      color: #c51b31;

      &:after,
      &:before {
        border-color: #c51b31;
      }
    }

    @media (max-width: @max_mobile) {
      order: 2;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    &:after,
    &:before {
      content: "";
      display: block;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      width: 6.5px;
      height: 6.5px;
      transition: 0.3s;
    }
    &.next {
      &:after {
        transform: rotate(45deg);
        margin-left: 30px;
      }
      &:before {
        display: none;
      }

      @media (max-width: @max_tablet) {
      }
    }
    &.prev {
      padding-right: 30px;
      &:after {
        display: none;
      }
      &:before {
        margin-right: 30px;
        transform: rotate(-135deg);
      }
    }
  }
}

.single-news {
  padding-top: 120px;
}

.page-title {
  &:extend(.fs-46);
  font-weight: bold;
  line-height: 60 / 46;
  margin: 0;
}

/*==============================================================
*												SIMPLE HEADER
=============================================================== */

.simple-header {
  position: relative;
  height: 500vw / 16vw;
  max-height: 800px;
  min-height: 450px;
  padding-bottom: 56px;
  padding-top: 20px;

  @media (max-width: @max_mobile) {
    padding-bottom: 40px;
  }
  .date {
    font-size: 14px;
    letter-spacing: 0.4em / 14em;
    line-height: 16 / 14;
    margin-bottom: 44em / 14em;
    @media (max-width: @max_mobile) {
      margin-bottom: 36px;
    }
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(20, 17, 17, 0) 0%, #141111 100%);
      opacity: 0.6;
    }

    & > * {
      flex-shrink: 0;
      flex-grow: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .container {
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}

/*==============================================================
*												SECTION SINGLE NEWS CONTENT
=============================================================== */

.editor-content() {
  color: #121212;

  font-size: 18px;
  line-height: 32 / 18;

  @media (max-width: @max_mobile) {
    // padding-top: 50px;
    // padding-bottom: 50px;

    font-size: 16px;
  }
  a {
    color: #000;
    &:hover {
      opacity: 0.6;
    }
  }
  p {
    margin-top: 0;
    padding: 0;
    margin-bottom: 2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:extend(.fs-24);
    font-weight: bold;
    line-height: 28 / 24;
    margin-bottom: 40em / 24em;
    @media (max-width: @max_tablet) {
      font-size: 20px;
    }
    @media (max-width: @max_mobile) {
      font-size: 20px;
    }
  }

  .back-link {
    display: flex;
    align-items: center;

    .svg-fill {
      fill: #c51b31;
    }
    .ico {
      margin-right: 20px;
      width: 20px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .text {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.editor-content {
  .editor-content();
}

.section-single-news-content {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}

/*==============================================================
*											    	tabs
=============================================================== */
.tab-content {
  display: none;
  &.active {
    display: block;
  }
}

.tabs-links,
.filter-links {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  white-space: nowrap;
  @media (max-width: @max_tablet) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.tab-item-link,
.filter-item-link {
  padding: 15px 20px;
  @media (max-width: @max_tablet) {
    padding: 15px;
  }
}

.tab-link,
.filter-link {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 16px;
  opacity: 0.5;
  transition: 0.3s;
  font-weight: bold;
  padding-bottom: 9px;

  &:after,
  &:before {
    transition: 0.3s;
    position: absolute;
    content: "";
    display: block;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 3px;
    background-color: #ff0033;
    opacity: 0;
  }
}

.tab-link:hover,
.filter-link:hover,
.tab-link.active,
.filter-link.active {
  opacity: 1;
  &:after,
  &:before {
    opacity: 1;
  }
  &:after {
    right: 0;
  }
  &:before {
    left: 0;
  }
}

/*==============================================================
*												accordion
=============================================================== */
.accordion-head {
  padding-bottom: 40px;
  position: relative;
  cursor: pointer;
  user-select: none;
  @media (max-width: @max_tablet) {
    padding-bottom: 30px;
  }
  transition: 0.3s;

  &:hover {
    color: #c51b31;
    .touch & {
      color: #000;
      &.active {
        color: #c51b31;
      }
    }
    .accordion-ico .ico {
      &:after,
      &:before {
        background-color: #c51b31;
      }
    }
  }
  &.active {
    color: #c51b31;
  }
}
.accordion-item {
  border-bottom: 1px solid rgba(18, 18, 18, 0.2);
  margin-bottom: 40px;
  &:last-child,
  &.last-item {
    margin-bottom: -40px;
    @media (max-width: @max_tablet) {
      margin-bottom: 0;
    }
  }
  @media (max-width: @max_tablet) {
    margin-bottom: 30px;
  }
}
.accordion-item:last-child {
  border-bottom: 0;
}
.accordion-title {
  &:extend(.fs-24);
  line-height: 28 / 24;
  font-weight: normal;
  letter-spacing: 0.8em / 24em;
  margin-bottom: 0;
  padding-right: 34px;
}

.accordion-ico {
  position: absolute;
  top: 0;
  right: 0;

  .ico {
    position: relative;
    &:after,
    &:before {
      transition: 0.3s;
      content: "";
      position: absolute;
      background-color: #121212;
      transition: 0.3s;
      display: block;
      opacity: 0.8;
    }
    &:before {
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
    }
    &:after {
      width: 1px;
      height: 100%;
      top: 0;
      left: 50%;
    }
  }
}

.accordion-head.active {
  .ico:after {
    height: 0;
    opacity: 0;
    transform-origin: center center;
    top: 50%;
  }
}

.accordion-body {
  .content {
    padding-bottom: 40px;
    padding-right: 45px;
    line-height: 1.7;
    @media (max-width: @max_tablet) {
      font-size: 16px;
    }
  }
}

.css-custom-scrollbar {
  &::-webkit-scrollbar {
    // width: 10px;
    height: 3px;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.22);
    height: 2px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
  }
}

.dom-scroll-drag {
  overflow: auto;
}
.hidden-scrollbar {
  overflow: hidden;
}

.hide-mobile {
  @media (max-width: @max_mobile) {
    display: none !important;
  }
}

.hide-tablet {
  @media (max-width: @max_tablet) {
    display: none !important;
  }
}

.hide-desktop {
  @media (max-width: @max_desktop) {
    display: none !important;
  }
}

.display-mobile {
  @media (min-width: @min_tablet) {
    display: none !important;
  }
}

.display-tablet {
  @media (min-width: @min_desktop) {
    display: none !important;
  }
}

.dom-absolute-fix {
  display: none;
  &.dom-position-fixed {
    display: block;
  }
}

*[data-dom-href] {
  cursor: pointer;
}

.show-additional-fields {
  opacity: 0.6;
  &:hover {
    color: #c51b31 !important;
    opacity: 1 !important;
  }
  &:not(.active) {
    .close-text {
      display: none;
    }
  }
  &.active {
    .open-text {
      display: none;
    }
  }
}

.additional-fields .form-col {
  padding-bottom: 10px;
}

.white-bg {
  background-color: #fff;
  color: #000;
  a {
    color: #000;
    &:hover {
      opacity: 0.6;
    }
  }
}

.lg-padding {
  @media (min-width: @min_tablet) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.no-header {
  // padding-top: 170px;
}

.title-header {
  padding-top: 170px;
  padding-bottom: 80px;
  position: relative;
  z-index: 10;
  .page-title {
    &:extend(.fs-60);
  }
}

.top-right-bg {
  transform: translate(0, -15%);
  width: 100%;
  left: 0;
  height: auto;
}

svg {
  path,
  rect {
    transition: 0.3s;
  }
}

.dom-modal-is-open,
.nav-is-open {
  overflow: hidden !important;
}

.dom-overflow-slider {
  overflow: hidden;
}

.dom-overflow-arrows {
  display: none;
  &.visible {
    display: flex;
  }

  align-content: stretch;

  .nav-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    background-color: red;
  }
}

.glide-controls {
  align-content: stretch;
  justify-content: space-between;
  display: none;
  padding-top: 40px;
  width: 54px;
  .svg-fill {
    fill: #fff;
  }
  &.active {
    display: flex;
  }
  & > * {
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      flex-shrink: 0;
      flex-grow: 0;
      height: 20px;
    }
  }
  .glide-prev {
    transform: rotate(180deg);
    transform-origin: center;
    padding-top: 3.5px;
  }
}

.dom-accordion {
  position: relative;
}

.filter-container,
.tab-content {
  transition: 0.3s;
}

.filter-item {
  transition: 0.3s;
}
.hide-filter {
  // transform: translateX(-100%);
  opacity: 0;
}

.center-col {
  justify-content: center;
}

.slider-arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 59%, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: @max_mobile) {
    width: 50px;
    height: 50px;
  }

  svg {
    flex-shrink: 0;
    flex-grow: 0;
    width: auto;
    height: 17px;
    path {
      transition: 0.3s;
      fill: #fff;
    }
  }
}

.will-animate {
  opacity: 0;
}

.animated-text-rows {
  // line-height: 1.3;
  line-height: 0 !important;
  .animate-row {
    line-height: 1.2;
    // display: block;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    span {
      display: block;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
      transform: translateY(100%);
      opacity: 0;
      transition-delay: 0.3s;
    }
    &:first-child span {
      transition-delay: 0s;
    }
  }
  &.visible {
    .animate-row span {
      transform: none;
      opacity: 1;
    }
  }
}

.opverflow-hide,
.overflow-hide {
  overflow: hidden;
}

.bottom-arrow,
.front-header .page-title {
  position: relative;
  .arrow {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 10px;
    background-color: #c51b31;
    transition: 0.3s;
    @media (max-width: @max_sm) {
      display: none;
    }
  }
}

.not-found-section {
  .bg-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
