/*==============================================================
*										SECTION ASKED QUESTIONS
=============================================================== */
.asked-questions-section {
  // background-color: #fff;
  color: #000;

  .accordion-col {
    width: 100%;
  }

  padding-top: 80px;
  // padding-bottom: 60px;
  padding-bottom: 0;

  @media (max-width: @max_tablet) {
    padding-top: 60px;
  }

  @media (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .white-bg {
    padding-top: 120px;
    padding-bottom: 120px;
    @media (max-width: @max_tablet) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
    @media (max-width: @max_mobile) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  a {
    color: #000;
    &:hover {
      opacity: 0.6;
    }
  }

  .section-title {
    &:extend(.fs-46);
    letter-spacing: 0.81em / 46em;
    margin: 0;
    margin-bottom: 40px;
    font-weight: bold;
    @media (max-width: 992px) {
      width: 100%;
      max-width: 480px;
    }
  }

  .container {
    display: flex;
    @media (max-width: 992px) {
      display: block;
    }
  }
  .title-col {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% / 12 * 5);
    padding-right: 7%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .display-tablet {
    .all-quest-row {
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
