/* ===============================================================
*                         NETWORK SECTION
=============================================================== */

.network-section {
  color: #fff;
  padding-top: 190px;
  padding-bottom: 120px;
  // padding-bottom: 200px;
  z-index: 2;

  .container {
    // margin-bottom: -60px;
  }

  @media (max-width: @max_tablet) {
    padding-top: 120px;
    padding-bottom: 70px;
  }

  @media (max-width: @max_mobile) {
    padding-top: 82px;
    padding-bottom: 80px;
  }

  @media (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .section-title {
    &:extend(.fs-46);
    font-weight: bold;
    line-height: 60 / 46;
    letter-spacing: 0.81em / 46em;
    width: 50%;
    margin-bottom: 120px;

    @media (max-width: @max_tablet) {
      margin-bottom: 100px;
    }
    @media (max-width: @max_mobile) {
      margin-bottom: 60px;
    }
    @media (max-width: @max_sm) {
      width: 100%;
    }
  }

  .btn-row {
    @media (max-width: @max_tablet) {
      padding-top: 60px;
    }
  }

  .network-card-row {
    margin-bottom: 80px;
    & > * {
      width: calc(100% / 4 - 1px);
    }

    @media (max-width: @max_tablet) {
      margin-bottom: 0;
    }

    @media (max-width: @max_mobile) {
      & > * {
        width: calc(100% / 2);
      }
    }

    @media (max-width: @max_xsm) {
      & > * {
        width: 100%;
      }
    }
  }

  .network-card-col {
    display: flex;
    @media (max-width: @max_mobile) {
      padding-bottom: 60px;
    }
  }
  .network-card {
    & > * {
      width: 100%;
    }

    .thumb-container {
      margin-bottom: 37px;
      @media (max-width: @max_tablet) {
        margin-bottom: 32px;
      }
    }

    .thumb {
      width: 70px;
      height: 70px;
      // width: 71px;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.07);
        transform-origin: center center;
        transition-duration: 0.3s;
        transform: scale(0);
      }
      & > * {
        width: 100%;
      }
    }

    .card-title {
      &:extend(.fs-24);
      font-weight: bold;
      line-height: 30 / 24;
      margin-bottom: 20px;
      @media (max-width: @max_mobile) {
        margin-bottom: 15px;
      }
    }

    .card-desc-container {
      max-width: 270px;
    }
    .card-desc {
      pointer-events: none;
      font-size: 14px;
      line-height: 24 / 14;
      transition: 0.3s;
      @media (min-width: @min_desktop) {
        transform: translateY(15px);
        opacity: 0;
      }

      @media (max-width: @max_tablet) {
        opacity: 0.6;
      }
    }

    @media (min-width: @min_desktop) {
      &:hover {
        .thumb:after {
          transform: scale(2.7);
        }

        .card-desc {
          transform: none;
          opacity: 0.6;
        }
      }
    }
  }

  .bg-img {
    // &:after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 120px;
    //   background: linear-gradient(to top, #121212, transparent);
    // }
  }
}

.device-tablet,
.device-mobile {
  .network-section {
    .network-card {
      .card-desc {
        transform: none;
        opacity: 1;
      }

      .thumb:after {
        display: none;
      }
    }
  }
}
