.section-contact-us {
  padding-top: 40px;
  padding-bottom: 40px;


  .section-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 40px;
  }

  .btn {
    padding-left: 6em;
    padding-right: 6em;
  }
}