/* ===============================================================
*                         OFFCANVAS MENU
=============================================================== */
.offcanvas-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: #261a1c;
  background-color: #121212;
  z-index: -1;
  padding-top: 120px;
  padding-bottom: 40px;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transform: translateX(-150vw);

  .menu-item {
    transform: translateX(-100%);
    opacity: 0;
    will-change: opacity, transform;
  }

  @delay: 0.05s;
  @duration: 0.8s;

  .menu-item:first-child {
    @count: 1s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(2) {
    @count: 2s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(3) {
    @count: 3s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(4) {
    @count: 4s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(5) {
    @count: 5s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(6) {
    @count: 6s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(7) {
    @count: 7s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(8) {
    @count: 8s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }
  .menu-item:nth-child(9) {
    @count: 9s;
    transition: opacity @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay *
        @count,
      transform @duration cubic-bezier(0.07, 0.51, 0.12, 1) @delay * @count;
  }

  &.active {
    .menu-item {
      transform: none;
      opacity: 1;
    }
  }

  &:not(.active) {
    .menu-item:after {
      width: 0;
      transition: none;
    }
  }

  .menu-item:after {
    transition: width 1s cubic-bezier(0.07, 0.51, 0.12, 1) 0.8s;
  }

  @media (max-width: @max_mobile) {
    padding-top: 100px;
  }
  @media (max-height: 600px) and (orientation: landscape) {
    padding-top: 93px;
  }
  .os-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
  }

  @media (max-width: @max_tablet) {
    padding-bottom: 0;
  }

  &.active {
    transform: none;
  }

  .offcanvas-menu-container {
    margin-top: auto;
    margin-bottom: auto;
  }

  .ofcanvas-menu {
    margin: auto;
    margin-left: 0;
    width: 100%;
    max-height: 100%;

    @media (max-width: @max_tablet) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media (max-height: 600px) and (orientation: landscape) {
      column-count: 2;
      .current-menu-item:after {
        display: none;
      }
    }
  }

  .menu-item a {
    font-size: inherit;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 0.53em / 30em;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 30px;
    @media (max-height: 600px) and (orientation: landscape) {
      font-size: 20px;
    }
  }

  .menu-item {
    &:extend(.fs-38);
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: @max_mobile) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      display: block;
      content: "";
      height: 3px;
      width: 0;
      background-color: #ff0033;
      flex-grow: 0;
      flex-shrink: 0;
    }

    a:hover {
      color: #fff;
    }
    @media (max-height: 600px) and (orientation: landscape) {
      margin-bottom: 20px;
    }
  }

  .current-menu-item {
    a {
      color: #fff;
    }
    &:after {
      width: 50px;
    }
  }

  .mobile-controls-container {
    padding-bottom: 20px;
    .contrlos-row {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: -30px;
      // margin-right: -30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 30px;
      & > * {
        padding: 0 30px;
      }
      @media (max-width: @max_mobile) {
        padding-bottom: 10px;
      }
      @media (max-height: 600px) and (orientation: landscape) {
        padding-bottom: 10px;
      }
    }
  }

  .become-partner-container {
    padding-bottom: 20px;
    padding-top: 20px;
    & > * {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100%;
    }
  }
}
