/* ===============================================================
*                      ADVANTAGES SECTION
=============================================================== */
.section-advantages {
  padding-bottom: 70px;
  padding-top: 80px;
  z-index: 1;

  .learn-more-link {
    &:not(.active) .visible-text {
      display: none;
    }
    &.active .hide-text {
      display: none;
    }
  }

  .arrows-wrap {
    @media (min-width: @min_sm) {
      display: none;
    }
    @media (max-width: @max_sm) {
      display: flex;
      padding-top: 40px;
      & > *:first-child {
        margin-right: 20px;
      }
    }
  }

  .learn-more-row {
    padding-top: 40px;
    @media (max-width: @max_mobile) {
      padding-top: 20px;
    }

    a {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media (max-width: @max_tablet) {
    padding-bottom: 40px;
  }

  .section-title {
    margin-bottom: 120px;
    .browser-ie & {
      padding-right: 10%;
    }
    @media (max-width: @max_tablet) {
      margin-bottom: 90px;
    }
    @media (max-width: @max_mobile) {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 60px;
    }
  }

  .svg-stroked {
    height: 1em;
    // width: 107%;
    width: 100%;
    font-size: 150px;
    // font-size: 170px;
    // transform: translateX(-6%);

    &.first-line {
      @media (max-width: @max_mobile) {
        margin-bottom: -6px;
      }
    }

    @media (max-width: @max_mobile) {
      tspan {
        stroke-width: 0.3px;
      }
    }
    &.second-line {
      // transform: translateX(14%);
      // width: 90%;
      @media (max-width: @max_mobile) {
        transform: none;
        width: 100%;
      }
    }
    @media (max-width: @max_tablet) {
      transform: none;
      font-size: 12vw;
      width: 100%;
      height: calc(1em + 15px);
    }
  }

  .chart-title {
    &:extend(.fs-46);
    margin-bottom: 42em / 46em;
    font-weight: bold;
  }

  .content-col {
    width: calc(100% / 12 * 4);
    padding-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;

    @media (max-width: @max_desktop) {
      width: calc(100% / 12 * 5);
      padding-left: calc(100% / 12 * 2 - 3%);
    }
    @media (max-width: @max_tablet) {
      width: calc(100% / 12 * 5);
      padding-left: calc(100% / 12 + 15px);
    }
    @media (max-width: 1023px) {
      padding-left: 0;
      padding-right: 7%;
    }
    @media (max-width: @max_mobile) {
      width: 70%;
      padding-right: 0;
      margin-bottom: 40px;
    }
    @media (max-width: @max_sm) {
      width: 100%;
    }
  }

  .buttons-col {
    position: absolute;
    left: -13%;
    top: 0;
    @media (max-width: @max_tablet) {
      left: 0;
      top: 0;
      transform: scale(0.7);
      transform-origin: left top;
    }
    @media (max-width: @max_mobile) {
      // position: static;
      order: 3;
    }
    @media (max-width: @max_sm) {
      display: none;
    }
    @media (max-width: @max_desktop) {
      left: 0;
    }
    @media (max-width: 1023px) {
      top: auto;
      left: 0;
      bottom: 0;
      transform-origin: bottom left;
      width: 143%;
    }

    .num {
      position: relative;
      z-index: 5;
      display: inline-block;
      transition: 0.3s;

      &:after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        content: "";
        display: block;
        height: 3px;
        background-color: #c51b31;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .buttons-wrap {
      display: flex;
      flex-direction: column;
      @media (max-width: 1023px) {
        flex-direction: row;
      }
    }

    .chart-button {
      color: rgba(255, 255, 255, 0.2);
      flex-shrink: 0;
      flex-grow: 0;
      margin-bottom: 25px;
      width: 80px;
      height: 85px;
      // height: 70px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      position: relative;
      transition: 0.3s;
      user-select: none;

      padding-bottom: 15px;

      @media (max-width: 1023px) {
        padding-top: 0;
        margin-bottom: 0;
        margin-right: 25px;
        padding-left: 20px;
      }

      .bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s;
        opacity: 0;
        z-index: -1;
        @media (max-width: 1023px) {
          transform: rotate(-90deg);
        }
      }

      @media (max-width: 1023px) {
        padding-bottom: 0;
        padding-right: 15px;
      }

      &.active {
        .bg {
          opacity: 1;
        }
        // &:after,
        // &:before {
        //   opacity: 1;
        // }
        color: #fff;
      }
      &:not(.active) {
        &:hover {
          // .num:after {
          //   opacity: 1;
          // }
          .num {
            color: #fff;
          }
        }
      }

      // background-size: 100% auto;
      // background-position: center center;
      // background-repeat: no-repeat;
      // background-image: url('../../img/icons/down.svg');
      // background-image: linear-gradient(to bottom, transparent, transparent);
      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background: linear-gradient(to bottom, transparent, #ff0033);
      //   display: block;
      //   opacity: 0;
      //   transition: 0.3s;
      //   @media (max-width: 1023px) {
      //     background: linear-gradient(to right, transparent, #ff0033);
      //   }
      // }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   display: block;
      //   border-style: solid;
      //   width: 0;
      //   height: 0;
      //   opacity: 0;
      //   transition: 0.3s;
      //   bottom: -20px;
      //   border-width: 20px 40px 0 40px;
      //   border-color: #ff0033 transparent transparent transparent;
      //   left: 0;
      //   @media (max-width: 1023px) {
      //     left: 100%;
      //     top: 0;
      //     border-width: 35px 0 35px 20px;
      //     border-color: transparent transparent transparent #ff0033;
      //   }
      // }
    }
  }

  .citation {
    font-style: italic;
    text-align: right;
    margin-bottom: 61px;
    font-size: 14px;
    line-height: 24 / 14;
    @media (max-width: @max_mobile) {
      margin-bottom: 40px;
    }
  }

  .chart-container {
    // height: 328px;
    max-width: 775px;
    margin-left: auto;
    height: 25vw;
    max-height: 328px;
    @media (max-width: @max_tablet) {
      margin-right: auto;
    }
    @media (max-width: @max_mobile) {
      width: 100%;
      max-width: none;
      height: 39vw;
    }
    .ax-x {
      font-size: 12px;
      letter-spacing: 0.6em / 16em;
      line-height: 19 / 16;
      @media (max-width: @max_mobile) {
        font-size: 12px;
      }
    }

    .ax-y {
      font-size: 14px;
      letter-spacing: 0.45em / 14em;
      @media (max-width: @max_mobile) {
        font-size: 12px;
      }
    }
  }

  .chart-col {
    width: 100%;
    padding-left: 20px;
    .img-wrap {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    @media (max-width: @max_tablet) {
      padding-left: 0;
    }
    @media (max-width: @max_mobile) {
      order: 2;
    }
  }

  .chart-desc {
    font-size: 16px;
    line-height: 26 / 16;
  }

  .chart-row {
    position: relative;
    display: flex;
    @media (max-width: 1023px) {
      padding-bottom: 110px;
    }
    @media (max-width: @max_mobile) {
      flex-direction: column;
    }
    @media (max-width: @max_sm) {
      padding-bottom: 0;
    }
  }

  .sliders-wrapper {
    display: flex;
    overflow: hidden;
    & > * {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media (max-width: @max_mobile) {
      margin-left: -40px;
      margin-right: -40px;
    }
    @media (max-width: @max_sm) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .slide {
    transition: 0.3s;
    opacity: 0;
    display: none;
    &.active {
      opacity: 1;
      display: flex;
    }

    &.hide-slide {
      opacity: 0;
    }
    @media (max-width: @max_mobile) {
      flex-direction: column;
      padding-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (max-width: @max_sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .chart-title {
    overflow: hidden;
    span {
      display: block;
      transform: translateY(100%);
      opacity: 0;
      transition-duration: 0.5s;
    }
  }

  .chart-desc,
  .learn-more-row {
    transition: 0.5s;
    opacity: 0;
  }

  .slide.animate {
    .chart-title span {
      transform: none;
      opacity: 1;
    }

    .chart-desc,
    .learn-more-row {
      opacity: 1;
    }
  }
}
