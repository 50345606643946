.site-wrap {
  transition-duration: 0s;
  &.animate-scroll {
    transition: 1s;
    transition-timing-function: ease-in-out;
    // transition-timing-function: cubic-bezier(0.07, 0.51, 0.12, 1);
  }
  // transition-timing-function: ease-in-out;
  will-change: transform;
}


.dom-fixed-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}


.scroll-view {
  position :relative;
  width: 1px;
}