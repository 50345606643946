/* ===============================================================
*                            NAVBAR
=============================================================== */

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: 0.3s;
  padding-top: 40px;
  padding-bottom: 40px;
}

.nav-in-scroll {
  background-color: @body_bg;
}
.top-nav {
  // transition: 0.3s;
  z-index: -1;
  max-width: 2260px;
  @media (min-width: 2259px) {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

.top-nav {
  // transition: 0.3s;
  .become-partner-btn {
    padding-top: 21px;
    padding-bottom: 21px;
  }

  .become-partner-container {
    @media (min-width: @min_tablet) {
      display: none;
    }
  }
}

.nav-in-scroll {
  // background-color: #121212;
  // .top-nav {
  padding-top: 15px;
  padding-bottom: 15px;
  // }
}

.top-nav {
  display: flex;
  align-content: stretch;
  justify-content: space-between;

  padding-left: 30px;
  padding-right: 30px;
  color: #fff;

  // transition: 0.3s;
  // &:extend(.fs-12);
  font-size: 14px;
  @media (max-width: @max_tablet) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: @col_space) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: @max_mobile) {
    font-size: 12px;
  }
  a,
  button {
    color: #fff;
    // &:extend(.fs-12);
    font-size: 14px;
    @media (max-width: @max_mobile) {
      font-size: 12px;
    }
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before,
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:after,
  .hamburger.is-active .hamburger-inner:before {
    background-color: #fff;
  }

  .left-col,
  .right-col {
    display: flex;
    align-items: center;
    position: relative;
    .browser-ie & {
      height: 100%;
    }
  }
  .right-col {
    margin-left: auto;
  }

  .become-btn-col {
    @media (max-width: @max_tablet) {
      position: absolute;
      right: 77px;
      .btn {
        white-space: nowrap;
      }
    }
    @media (max-width: @max_mobile) {
      right: 52px;
    }
    @media (max-width: @max_sm) {
      display: none;
    }
  }

  .left-col {
    @media (max-width: @max_tablet) {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .logo-col {
    display: flex;
    align-items: center;
    padding-left: 40px;

    @media (max-width: @max_tablet) {
      padding-left: 0;
    }
    .logo-text {
      display: block;
      padding-left: 20px;
      text-transform: uppercase;
      // &:extend(.fs-14);
      font-size: 14px;
      line-height: 20 / 14;
      font-weight: bold;
      letter-spacing: 0.6em / 14em;
      @media (max-width: 420px) {
        font-size: 12px;
      }
    }

    .logo-ico {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: #c51b31;
      // @media ( max-width: 420px ) {
      //   width: 40px;
      //   height: 40px;
      // }
    }
  }

  .right-col > * {
    padding-right: 60px;
    &:last-child {
      padding-right: 0;
    }
  }

  .account-link {
    display: flex;
    align-items: center;
    .svg-stroke {
      stroke: #fff;
    }
    &:hover {
      .svg-stroke {
        stroke: #c51b31;
      }
    }
    .text {
      display: block;
      padding-left: 10px;
      text-transform: uppercase;
      white-space: nowrap;
      margin-top: 1px;
    }
    .ico {
      width: 16px;
    }
  }

  .lang-col {
    .open-dropdown.active,
    .dropdown-content.active {
      background-color: #fff;
      color: #121212;
      a,
      button {
        color: #121212;
        &:hover {
          color: #c51b31;
        }
      }
    }

    .open-dropdown {
      position: relative;
      z-index: 1;
    }

    .dropdown-content {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }

    .open-dropdown:hover,
    .open-dropdown.active:hover {
      .svg-stroke {
        stroke: #c51b31;
      }
    }

    .open-dropdown.active .svg-stroke {
      stroke: #121212;
    }

    .open-dropdown.active:hover {
      color: #c51b31;
      background-color: #f0f0f0;
    }

    .open-dropdown,
    .dropdown-content > * {
      padding-left: 20px;
      padding-right: 20px;
    }

    .dropdown-content {
      top: 100%;
      .dropdown-text {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      & > * {
        display: block;
      }
    }
  }

  .lang-col,
  .account-col {
    .btn,
    a.btn {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
