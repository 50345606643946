
/*==============================================================
*												FIXED SOCIALS
=============================================================== */


.fixed-socials {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 50;
  width: 50px;
  padding-bottom: 50px;

  &:hover, &.active {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &.is-hidden {
    display: none;
  }
  .socials-container:not(.dom-slide-up) + .open-social-btn {
    z-index: -1;
  }
  .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
    transition: .3s;
  }
  .hamburger.is-active:hover, .hamburger:hover {
    opacity: 1;
  }
  .close-social-btn:hover {
    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
      background-color: #ff0033;
    }
    background-color: #F0F0F0; 
  }
  .close-social-btn {
    background-color: #fff;
  }
  .close-social-btn, .open-social-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .close-social-btn {
    opacity: 0;
  }
  .open-social-btn {
    z-index: 2;
    background-color: #4D4D4F;
    .svg-stroke {
      stroke: #fff;
    }
    &:hover {
      background-color: #fff;
      .svg-fill {
        fill: #ff0033 ;
      }
      .svg-stroke {
        stroke: #ff0033 ;
      }
    }
  }
  .open-social-btn.active {
    opacity: 0;
    & ~ .close-social-btn {
      opacity: 1;
    }
  }
  .socials-container {
    bottom: 50px;
    left: 0;
    overflow: hidden;
  }
  .social-ico {
    display: block;
    height: 50px;
    width: 100%;
  }

  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    width: 22px!important;
  }
  .hamburger-box {
    width: 21px!important;
    height: 19px!important;
  }
  .hamburger{
    height: 19px!important;
  }
  .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
    background-color: #000;
  }

  .social-ico {
    display: block;
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .fb-fill {
      fill: #000;
    }
    .mail-fill {
      fill: #000;
    }

    &:hover {
      background-color: #F0F0F0;
      .fb-fill {
        fill: #ff0033;
      }
      .mail-fill {
        fill: #ff0033;
      }
      .in-fill-bg {
        fill: #ff0033;
      }
      .tel-fill-bg {
        fill: #ff0033;
      }
    }
  }
  .socials-container {
    width: 100%;
  }
}




