/*==============================================================
*												RECENT NEWS SECTION
=============================================================== */
.recent-news-section {
  padding-top: 100px;
  padding-bottom: 60px;

  @media (max-width: @max_mobile) {
    padding-top: 60px;
    padding-bottom: 20px;
    .section-title {
      text-align: left !important;
    }
  }

  @media (max-width: 576px) {
    padding-top: 40px;
  }

  .section-title {
    text-align: center;
    &:extend(.fs-46);
    font-weight: bold;
    margin-bottom: 60em / 46em;
  }
  .more-news-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    a:hover {
      opacity: 0.6;
    }
  }
}
