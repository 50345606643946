.section-contact {
  padding-bottom: 40px;


  .form-rules {
    transform: translateY(-20px);
  }
  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    @media ( min-width: @min_tablet ) {
      padding-left: 80px;
      padding-right: 80px; 
    }
    display: flex;
    & > * {
      width: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      @media ( max-width: @max_tablet ) {
        width: 100%;
      }
    }
    @media ( max-width: @max_tablet ) {
      display: block;
    }
  }

  .form-row:last-child .form-col {
    padding-bottom: 0;
  }

  .form-contact-col {
    padding-right: 7%;
    @media ( max-width: @max_tablet ) {
      padding-right: 0;
      margin-bottom: 40px;
    }
  }
  .contacts-col {
    padding-left: 7%;
    padding-top: 83px;
    @media ( max-width: @max_tablet ) {
      padding-left: 0;
      padding-top: 0;
    }
  }


  .section-title {
    &:extend(.fs-34);
    font-weight: bold;
    margin-bottom: 40em / 34em;
  }
  .col-title {
    text-transform: uppercase;
    font-weight: bold; 
    letter-spacing: 0.6em / 12em;
    font-size: 12px;
    margin-bottom: 14em / 12em;
  }

  .socials-row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
  }

  .social-col {
    padding: 0 10px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(18, 18, 18, 0.1);
    background-color: #fff;
    
    @media ( max-width: @max_mobile ) {
      width: 50px;
      height: 50px;
    }

    .fb-fill {
      fill: #000;
    }
    .mail-fill {
      fill: #000;
    }

    &:hover {
      opacity: 1;
      background-color: #C51B31;
      border-color: #C51B31;
      .tel-fill-bg {
        fill: #fff;
      }
      .tel-fill-center {
        fill: #000;
      }
      .fb-fill {
        fill: #fff;
      }
      .mail-fill {
        fill: #fff;
      }
      .in-fill-bg {
        fill: #fff;
      }
      .in-fill-center {
        fill: #000;
      }
    } 
  }
}