.footer-wrap {
  // padding-top: 40px;
  margin-top: auto;
}
.footer {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 50px;

  @media (max-width: 992px) {
    display: block;
  }

  .foot-menu,
  .foot-socials {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .foot-menu {
    margin: 0 -23px;
    margin-right: auto;
    @media (max-width: 992px) {
      margin-bottom: 25px;
    }
    @media (max-width: @max_mobile) {
      flex-direction: column;
      margin: 0;
      margin-bottom: 40px;
    }
  }

  .menu-item a {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.5;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  .menu-item {
    padding: 0 23px;
    @media (max-width: @max_mobile) {
      padding: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .foot-socials {
    margin: 0 -15px;
  }

  .social-item {
    padding: 0 15px;
  }

  .social-item .ico {
    width: 21px !important;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  .tel-fill-bg,
  .in-fill-bg {
    fill: #fff;
  }
  .tel-fill-center,
  .in-fill-center {
    fill: #000;
  }

  position: relative;
  .age {
    opacity: 0.3;
    position: relative;
    padding-right: 20px;
    @media (max-width: 991.9px) {
      width: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .ico {
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        top: 0;
        position: static;
      }
      // transform: translateY(50%);
    }
    .ico,
    svg {
      width: 40px !important;
      height: 40px !important;
      flex-shrink: 0;
    }
    .svg-fill {
      fill: #fff;
    }
  }
}

.footer-copyrights {
  background-color: #000;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    content: "";
    @media (max-width: @max_mobile) {
      width: 100%;
    }
  }
  .text {
    text-align: center;
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
  }
}
